import React, { useState } from 'react';
import {useLocation} from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { composeUrl } from "../../utils/api";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";

function Home() {
    const { state } = useLocation();
    const auth = useAuth();
    const [loading, setLoading] = useState<boolean>(true);

    const [iframeId, setIframeId] = useState<string>("emed");
    const [iframeSrc, setIframeSrc] = useState<string>("about:blank");

    const [allowedOrigin, setAllowedOrigin] = useState<string>();

    const fetchEmedSession = () => {
        setLoading(true);
        fetch(composeUrl('emedsession'), {
            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
            method: 'POST'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setIframeId(data.iframeId);
                setIframeSrc(data.sourceUrl)
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchAllowedOrigin = () => {
        setLoading(true);
        fetch(composeUrl('configuration/emed'))
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAllowedOrigin(data.baseUrl);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        fetchEmedSession();
        fetchAllowedOrigin();
    }, []);

    window.addEventListener('message', (event) => {
        if (!allowedOrigin
            || event.origin !== allowedOrigin) {
            return;
        }

        let message;
        try {
            message = JSON.parse(event.data);
        } catch (e) {
            console.error('Invalid JSON:', event.data);
            return;
        }

        if (message.schema !== 'EVENT' || message.version !== '1.0') {
            console.error('Unsupported message:', message);
            return;
        }

        switch (message.type) {
            case 'appointment.booked':
                const appointmentId = message.data.appointment_id;
                const patientId = message.data.patient_id;
                console.log(`Received appointment.booked event with appointment ID ${appointmentId}`);
                break;
            default:
                console.error('Unknown message type:', message.type);
                return;
        }
    });

    return (
        <div className="relative overflow-hidden pb-iframe-sm h-0 lg:pb-iframe-lg">
            <LoadingSpinner loading={loading}>
                <iframe
                    id={iframeId}
                    src={iframeSrc}
                    allowFullScreen={true}
                    allow={"camera *; microphone *"}
                    className="absolute top-0 left-0 w-full h-full"
                >
                </iframe>
            </LoadingSpinner>
        </div>
    );
}

export default Home;