import React from 'react';
import imgOnCall from "../../images/dashboard/oncall.png";

interface Props {
    productName: string;
    text: string;
    imgSrc: string;
}

function ProductHeader(props: Props) {
    return (
        <div id="productHeader" className="col-span-12 mb-xl sm:mb-2xl order-1 sm:order-none row-start-1">
            <div className="rounded-xl bg-lime-grey-dark py-2 px-1 sm:py-2 sm:px-7 font-medium space-x-4 grid grid-cols-12 productHeaderTriangle">
                <div className="col-span-4 md:col-span-3 lg:col-span-2 xl:col-span-1 flex items-center order-2 sm:order-1 justify-end sm:justify-start">
                    <img src={props.imgSrc} alt={props.productName} className="w-26 h-38"/>
                </div>
                <div className="col-span-8 md:col-span-9 lg:col-span-6 xl:col-span-8 flex align-middle order-1 sm:order-2 py-3">
                    <p className="text-p2 sm:text-p0 p-lg max-w-45" dangerouslySetInnerHTML={{__html: props.text}}></p>
                </div>
                <div className="lg:col-span-4 xl:col-span-3 order-3 hidden lg:flex flex-col justify-center">
                    <img src={imgOnCall} alt="OnCall" className="h-full max-h-[6em] xl:max-h-[7.5rem]"/>
                </div>
            </div>
        </div>
    );
};

export default ProductHeader;