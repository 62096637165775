import React from 'react';
import {useNavigate} from "react-router-dom";
import OptOutCard from '../OptOut/OptOutCard';
import ProductHeader from "../Product/ProductHeader";
import imgNurse from "../../images/cancer-navigator/nurse_1.png";
import imgHands from "../../images/cancer-navigator/hands_cn.png";
import OutlineArrowhead from '../../images/dashboard/full_arrowhead.png';
import imgInfo from "../../images/health-essentials/info_icon.png";

function ProductInfo() {
    const navigate = useNavigate();
    const navigateToHome = () => navigate("/cancer", {state: {showCancerNavigatorHome: true}});
    const navigateToDocs = () => navigate("/documents");
    const summaryUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/CancerNavigator/Lime+OnCall+Cancer+Navigator+Product+%26+Service+Summary+052024.pdf";
    const faqsUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/CancerNavigator/Cancer+Navigator+FAQs.pdf";

    return (
        <div>
            <div className="pt-sm sm:pt-lg md:mb-xl">
                <div className="grid grid-cols-12 w-full gap-y-4">
                    <ProductHeader
                        productName="Cancer Navigator"
                        text="Understanding and managing cancer can be daunting. Here you'll find straightforward access to resources, expert guidance, and timely support. <strong>Let's face this together.</strong>"
                        imgSrc={imgNurse}
                    />

                    <div className="mt-md md:mt-0 sm:mb-lg md:mb-0 col-span-12 md:col-span-6 px-2 order-6 md:order-none md:row-start-2 row-span-2">
                        <div className="col-span-6 p-2 row-start-2 flex flex-col justify-between">
                            <div className={`dashboardTileProductTitle p-4 w-full mb-8 hidden md:flex`}>
                                <div className={`relative w-full`}>
                                    <h3 className={`text-pure-white bg-lime-bg m-0 py-4 pr-5 pl-28 font-sans-co font-bold text-h3 lg:text-h2 rounded-xl`}>
                                    <span
                                        className={`w-32 h-32 rounded-full bg-lime-bg inline-block absolute -top-5 -left-4 flex items-center justify-center`}>
                                        <span
                                            className={'z-10 w-24 h-24 rounded-full absolute top-4 left-4 bg-lime-green'}>
                                        </span>
                                        <img src={OutlineArrowhead} alt="Icon" className="w-16 h-16 z-20"/>
                                    </span> Cancer Navigator
                                    </h3>
                                </div>
                            </div>
                            <img src={imgHands} alt="Cancer Navigator" className="w-full h-auto"/>
                        </div>
                    </div>

                    <div className="productAction px-2 md:mb-lg text-center col-span-12 md:col-span-6 lg:col-span-3 order-3 md:order-none md:row-span-2 md:row-start-2 lg:row-start-2 lg:row-span-1 md:h-full">
                        <div className="md:h-full">
                            <span
                                onClick={navigateToHome}
                                className="rounded-xl bg-lime-orange p-4 font-medium text-lime-bg items-center
                                space-x-4 cursor-pointer flex justify-center
                                font-sans-co h-full">
                                <div className="flex flex-col h-full justify-evenly">
                                    <h2 className="text-lime-blue text-p0 md:text-2xl md:text-4xl xl:text-h1
                                        flex flex-col align-middle productPageAction">
                                        <strong className="me-1">GET SUPPORT</strong>
                                        <span className="font-extralight text-p0">Find out which services are available to you</span>
                                    </h2>
                                </div>
                            </span>
                        </div>
                    </div>

                    <div className="productAction px-2 md:mb-lg col-span-12 md:col-span-6 lg:col-span-3 order-4 md:order-none md:row-start-4 lg:row-start-2 md:h-full">
                        <div className="md:h-full">
                            <div
                                onClick={navigateToDocs}
                                className="rounded-xl bg-lime-fg p-4 font-medium text-lime-bg items-center
                                    space-x-4 cursor-pointer flex justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                <div
                                    className="text-center flex flex-row justify-between
                                        md:flex-col items-center md:justify-center w-full h-full">
                                    <h2 className="text-lime-blue md:mb-4 text-h4 md:text-h2 productPageAction">Documents</h2>
                                    <img src={imgInfo} alt="info" className="w-10 h-10 md:w-24 md:h-24"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="productAction px-2 md:mb-lg col-span-12 md:col-span-6 xl:col-span-3 order-2 md:order-none md:row-start-4 xl:row-start-3 md:h-full">
                        <div className="md:h-full">
                            <a
                                href={summaryUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="rounded-xl bg-lime-turk p-4 font-medium text-lime-bg items-center
                                    space-x-4 cursor-pointer flex justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                <div
                                    className="text-center flex flex-row justify-between
                                        md:flex-col items-center md:justify-center w-full h-full">
                                    <h2 className="text-lime-blue md:mb-4 text-h4 md:text-h2 productPageAction">Service Summary</h2>
                                    <img src={imgInfo} alt="info" className="w-10 h-10 md:w-24 md:h-24"/>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="productAction px-2 md:mb-lg col-span-12 md:col-span-6 xl:col-span-3 order-5 md:order-none md:row-start-5 lg:row-start-4 xl:row-start-3 md:h-full">
                        <div className="md:h-full">
                            <a
                                href={faqsUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="rounded-xl bg-lime-grey-dark p-4 font-medium text-lime-bg items-center
                                    space-x-4 cursor-pointer flex justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                <div
                                    className="text-center flex flex-row justify-between
                                        md:flex-col items-center md:justify-center w-full h-full">
                                    <h3 className="text-lime-blue md:mb-4 text-h4 md:text-h2 productPageAction">FAQ</h3>
                                    <img src={imgInfo} alt="info" className="w-10 h-10 md:w-24 md:h-24"/>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <OptOutCard/>
        </div>
    );
}

export default ProductInfo;